import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {

  constructor(private http: HttpClient, private router: Router) { }
  
  login(){
    this.router.navigateByUrl("/login");
  }
}
