import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-edithouse',
  templateUrl: './edithouse.component.html',
  styles: [
  ]
})
export class EdithouseComponent implements OnInit {

  token: any;
  page: string;
  payload: any;
  user: string;
  houseForm: FormGroup;
  isOwner: string;
  display: boolean;
  lat: any;
  long: any;
  plotid: number;
  id: number;
  ownerId: string;
  imgURL: string;
  houseid: number;
  loadList: string[];

  readonly APIURL = environment.apiUrl;
  
  constructor(private http: HttpClient, 
    private toastr: ToastrService, 
    private router: Router, 
    private formBuilder: FormBuilder,  
    private sharedService: SharedService,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.houseid = parseInt(this.activatedRoute.snapshot.paramMap.get('id')); 
    this.page = this.activatedRoute.snapshot.paramMap.get('page');
    this.page = this.page.replace("-","/");
    this.token = localStorage.getItem("token");
    if(this.token != null && this.token != "" && this.token != "Undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.user = this.payload.username;
      this.getHouse();
    }
    else{
      this.router.navigateByUrl("/logout");
    }
  }

  getHouse(){
    var loadArray: string[];
    var loadString;
    this.SpinnerService.show(); 
    this.http.get(this.APIURL + "/house/" + this.houseid).subscribe(
      (res:any) => {
        loadString = res.loadList;
        this.loadList = loadString.split(',');        
        console.log(loadArray);
        console.log(this.loadList);
        this.plotid = res.plotid;
        this.lat = res.latitude;
        this.long = res.longitude;
        this.ownerId = res.idPhoto;
        this.houseForm = this.fb.group(
        {
          houseid: [res.houseid, Validators.required],
          latitude: [res.latitude, Validators.required],
          longitude: [res.longitude, Validators.required],
          peopleCount: [res.peopleCount, Validators.required],
          construction: [res.construction, Validators.required],
          bedrooms: [res.bedrooms, Validators.required],
          electricity: [res.electricity, Validators.required],
          connectionPhase: [res.connectionPhase, Validators.required],
          connectionType: [res.connectionType, Validators.required],
          consumerType: [res.consumerType, Validators.required],
          loadList: [this.loadList],
          remark: [res.remark],
          owner: [res.owner, Validators.required],
          name: [res.name, Validators.required],
          middleName: [res.middleName],
          surname: [res.surname, Validators.required],
          dob: [res.dob, Validators.required],
          gender: [res.gender, Validators.required],
          idNumber: [res.idNumber, Validators.required],
          telephone: [res.telephone, Validators.required],
          email: [res.email, Validators.required]        
        });                
        this.SpinnerService.hide();
      },
      err => {
        this.SpinnerService.hide();
        console.log(err);
        this.sharedService.errorStatus(err.status);
      }
    );
  }

  updateHouse(){
    //Start loading
    this.SpinnerService.show();
    this.houseForm.value.houseid = this.houseid;
    this.houseForm.value.loadList = this.houseForm.value.loadList.toString();
    this.houseForm.value.actionId = this.user;
    this.houseForm.value.isDelete = 0;
    console.log(this.houseForm.value.loadList);
    this.http.patch(this.APIURL + "/house/" + this.houseid, this.houseForm.value).subscribe(
    res => {
      this.SpinnerService.hide();
      console.log(this.houseForm.value);        
      this.toastr.success("House updated successfully");
      this.router.navigateByUrl(this.page);      
    },
    err => {
      console.log(err);
      this.SpinnerService.hide();
      this.sharedService.errorStatus(err.status);        
    });
  }

  formReset(){
    this.houseForm.reset();
  }

  occupantInfo(val){
    if(val === 'Oui'){
      //Start loading
      this.SpinnerService.show();
      this.http.get(this.APIURL + "/plot/" + this.plotid).subscribe(
        (res:any) => {
          this.ownerId = res.idPhoto;
          this.houseForm = this.fb.group({
            latitude: [this.houseForm.value.latitude, Validators.required],
            longitude: [this.houseForm.value.longitude, Validators.required],
            peopleCount: [this.houseForm.value.peopleCount, Validators.required],
            construction: [this.houseForm.value.construction, Validators.required],
            bedrooms: [this.houseForm.value.bedrooms, Validators.required],
            electricity: [this.houseForm.value.electricity, Validators.required],
            connectionPhase: [this.houseForm.value.connectionPhase, Validators.required],
            connectionType: [this.houseForm.value.connectionType, Validators.required],
            consumerType: [this.houseForm.value.consumerType, Validators.required],
            loadList: [this.houseForm.value.loadList, Validators.required],
            remark: [this.houseForm.value.remark],
            owner: [val],
            name: [res.name, Validators.required],
            middleName: [res.middleName],
            surname: [res.surname, Validators.required],
            dob: [res.dob, Validators.required],
            gender: [res.gender, Validators.required],
            idNumber: [res.idNumber, Validators.required],
            telephone: [res.telephone, Validators.required],
            email: [res.email, Validators.required]
          });
          console.log(res);
          this.SpinnerService.hide();
        }
      );
      this.display = true;
    }
    else{
      this.SpinnerService.show();
      this.http.get(this.APIURL + "/house/" + this.houseid).subscribe(
      (res:any) => {
        this.display = true;
        this.houseForm = this.fb.group({
          latitude: [this.houseForm.value.latitude, Validators.required],
          longitude: [this.houseForm.value.longitude, Validators.required],
          peopleCount: [this.houseForm.value.peopleCount, Validators.required],
          construction: [this.houseForm.value.construction, Validators.required],
          bedrooms: [this.houseForm.value.bedrooms, Validators.required],
          electricity: [this.houseForm.value.electricity, Validators.required],
          connectionPhase: [this.houseForm.value.connectionPhase, Validators.required],
          connectionType: [this.houseForm.value.connectionType, Validators.required],
          consumerType: [this.houseForm.value.consumerType, Validators.required],
          loadList: [this.houseForm.value.loadList, Validators.required],
          remark: [this.houseForm.value.remark],
          owner: [val],
          name: [res.name, Validators.required],
          middleName: [res.middleName],
          surname: [res.surname, Validators.required],
          dob: [res.dob, Validators.required],
          gender: [res.gender, Validators.required],
          idNumber: [res.idNumber, Validators.required],
          telephone: [res.telephone, Validators.required],
          email: [res.email, Validators.required]
        });
        console.log(res);
        this.SpinnerService.hide();
      });              
    }
  }
}
