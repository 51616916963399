import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-useraccess',
  templateUrl: './useraccess.component.html',
  styles: [
  ]
})
export class UseraccessComponent implements OnInit {

  actionid: string;
  token: any;
  payload: any;
  userList: any;
  chkUpdate: boolean = false;

  readonly APIURL = environment.apiUrl;
  readonly BaseURL = environment.baseUrl;

  constructor(
    private fb: FormBuilder, 
    private http: HttpClient, 
    private toastr: ToastrService,
    private sharedService: SharedService,
    private router: Router,
    private SpinnerService: NgxSpinnerService ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem("token");
    if(this.token != null && this.token != "" && this.token != "Undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.actionid = this.payload.username;
      this.UserList();
    }
    else{
      this.router.navigateByUrl("/logout");
    }    
  }

  //Create User Form  
  formModel = this.fb.group({
    userid: '',
    username:['', Validators.required],
    password:['', Validators.required],
    role: 'User'
  });
  
  UserList(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/user").subscribe(
      (res:any) => {
        this.userList = res;
        this.SpinnerService.hide();
      },
      err => {
        this.sharedService.errorStatus(err.status);
        this.SpinnerService.hide();
      }
    );
  }

  userDelete(user){
    this.SpinnerService.show();
    user.isActive = 0;
    user.isDelete = 1;
    user.actionId = this.actionid;
    this.http.patch(this.APIURL + "/user", user).subscribe(
      res => {
        this.SpinnerService.hide();
        this.UserList();
        this.toastr.success("User deleted successfully");
      },
      err => {
        console.log(err);
        this.sharedService.errorStatus(err.status);
        this.SpinnerService.hide();
      }
    );
  }

  editUser(user){
    this.chkUpdate = true;
    this.formModel = this.fb.group({
      userid: user.userid,
      username:[user.username, Validators.required],
      password:["", Validators.required],
      role: [user.role, Validators.required]
    });
  }

  onSubmit(){    
    if(this.formModel.value.userid == "" && !this.chkUpdate){
      this.newUser();
    }
    else{
      this.updatePassword();
    }
  }

  // Update the user
  updatePassword(){
    var docelement = <HTMLInputElement> document.getElementById("submit");
    docelement.innerHTML ="<i class='fa fa-refresh fa-spin'></i> Please wait";
    docelement.disabled = true;
    var user = {
      userid: this.formModel.value.userid,
      newPassword: this.formModel.value.password 
    }

    this.http.post(this.BaseURL + "/auth/reset-password", user).subscribe(
      res => {
        this.formModel.reset(); 
        this.chkUpdate = false;
        this.toastr.success("Password reset successfully");
        docelement.innerHTML ="<i class='fa fa-dot-circle-o'></i> Submit";
        docelement.disabled = false;
      },
      err => {
        this.sharedService.errorStatus(err.status);
        docelement.innerHTML ="<i class='fa fa-dot-circle-o'></i> Submit";
        docelement.disabled = false;
      }
    )
  }

  // Create new user
  newUser(){
    var docelement = <HTMLInputElement> document.getElementById("submit");
    docelement.innerHTML ="<i class='fa fa-refresh fa-spin'></i> Please wait";
    docelement.disabled = true;
    this.formModel.value.username = this.formModel.value.username.toUpperCase();
    this.formModel.value.actionId = this.actionid;
    this.formModel.value.isActive = 1;
    this.formModel.value.isDelete = 0;

    this.http.post(this.APIURL + "/user", this.formModel.value).subscribe(
      res => {
        this.UserList();
        this.formModel.reset();        
        this.toastr.success("User added successfully");
        docelement.innerHTML ="<i class='fa fa-dot-circle-o'></i> Submit";
        docelement.disabled = false;
      },
      err => {
        if(err.status == 409){
          this.UserList();
          this.formModel.reset(); 
          this.toastr.success("User re-activated successfully");
        }
        else{
          this.sharedService.errorStatus(err.status);
        }        
        docelement.innerHTML ="<i class='fa fa-dot-circle-o'></i> Submit";
        docelement.disabled = false;
      }
    );

  }

  // Change user active status
  onChange(user, isCheck: boolean){
    var message;
    if(isCheck){
      user.isActive = 1;
      message = "User access activated successfully";
    }
    else{
      user.isActive = 0;
      message = "User access deactivated successfully";
    }   
    user.isDelete = 0;
    user.actionId = this.actionid;

    this.http.patch(this.APIURL + "/user", user).subscribe(
    res => {
      this.toastr.success(message, "Successful");      
    },
    err => {
      console.log(err);
      this.sharedService.errorStatus(err.status);
    });
  }
}
