import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AuthGuard } from './auth/auth.guard';
import { LogoutComponent } from './pages/logout/logout.component';
import { PlotListComponent } from './pages/plot-list/plot-list.component';
import { HouseListComponent } from './pages/house-list/house-list.component';
import { AllHousesComponent } from './pages/all-houses/all-houses.component';
import { UseraccessComponent } from './pages/useraccess/useraccess.component';
import { EditplotComponent } from './pages/editplot/editplot.component';
import { EdithouseComponent } from './pages/edithouse/edithouse.component';
import { MapviewComponent } from './pages/mapview/mapview.component';
import { PlotviewComponent } from './pages/plotview/plotview.component';
import { HouseviewComponent } from './pages/houseview/houseview.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'plotList',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'logout',
    component: LogoutComponent,    
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [      
      {
        path: 'map',
        component: MapviewComponent,
        data: {
          title: 'Map View'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'plotList',
        component: PlotListComponent,
        data: {
          title: 'Liste des parcelles'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'editPlot/:id',
        component: EditplotComponent,
        data: {
          title: 'Modifier la parcelle',
          permittedRoles: ['Admin']
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'plotView/:id',
        component: PlotviewComponent,
        data: {
          title: 'Détails de la parcelle'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'houseList/:id',
        component: HouseListComponent,
        data: {
          title: 'Liste des maisons'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'houseView/:id',
        component: HouseviewComponent,
        data: {
          title: 'Détails de la mansion'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'allHouses',
        component: AllHousesComponent,
        data: {
          title: 'Liste des maisons'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'editHouse/:id/:page',
        component: EdithouseComponent,
        data: {
          title: 'Modifier la mainson',
          permittedRoles: ['Admin']
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        component: UseraccessComponent,
        data: {
          title: 'Liste des utilisateur',
          permittedRoles: ['Admin']
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'map',
        component: MapviewComponent,
        data: {
          title: 'Map View'
        },
        canActivate: [AuthGuard]
      },
/*
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
*/
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
