import { INavData } from '@coreui/angular';

export const navUsers: INavData[] = [
  {
    name: 'Liste des parcelles',
    url: '/plotList',
    icon: 'icon-home'
  },
  {
    name: 'Liste des maisons',
    url: '/allHouses',
    icon: 'icon-home'
  },
  {
    name: 'Mot de passe',
    url: '/changePassword',
    icon: 'icon-key'
  },
  {
    name: 'Sortie',
    url: '/logout',
    icon: 'icon-logout'
  }
];
