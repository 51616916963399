import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styles: ['agm-map { height: 500px; /* height is required */ }'
  ]
})
export class MapviewComponent implements OnInit {

  markers: any;
  latitude: any;
  longitude: any;
  propList: any;
  readonly APIURL = environment.apiUrl;

  constructor(private SpinnerService: NgxSpinnerService, private sharedService: SharedService, private http: HttpClient) { }

  ngOnInit(): void {
    this.latitude = -4.3496937;
    this.longitude = 15.2811075;
    this.getProperty();
    this.markers = [      
      { lat: -4.3496937, lng: 15.2811075, alpha: 1 },
      { lat: -4.3508136, lng: 15.2761253, alpha: 1 },
      { lat: -4.3007321, lng: 15.3156313, alpha: 1 },
      { lat: -4.300735, lng: 15.3156284, alpha: 1 },
      { lat: -4.3215596, lng: 15.2785951, alpha: 1 },
      { lat: -4.3388002, lng: 15.323143, alpha: 1 }
    ];
  }

  // to get list of all property
  getProperty(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/plot/count").subscribe(
      (res:any) => {       
        this.propList = res[0];
        console.log(this.propList);
        this.SpinnerService.hide();
      },
      err => {       
        this.SpinnerService.hide(); 
        this.sharedService.errorStatus(err.status);
      }
    )
  }

}
