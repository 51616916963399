import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(private router: Router, private toastr: ToastrService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(localStorage.getItem('token') !=null){
        let roles = next.data['permittedRoles'] as Array<string>;
        if(roles){

          if(this.roleMatch(roles)) return true;
          else{
            this.router.navigateByUrl("404");
            console.log(roles);
          }
        }
        return true;
      }
      else{        
        this.router.navigateByUrl('login');        
        this.toastr.error("Your session is expired! Please login again", "Error!");
        return false;        
      }
  }

  // Check role is allowed or not 

  roleMatch(allowedRoles):boolean{
    var isMatch = false;
    var payload = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payload.role;
    allowedRoles.forEach(element => {
      if(userRole == element){
        isMatch = true;
        return false;
      }
    });
    console.log(userRole);
    return isMatch;
  }
  
}
