import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
//import { Workbook } from 'exceljs';
import * as Excel from "exceljs/dist/exceljs.min.js";

import * as fs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-plot-list',
  templateUrl: './plot-list.component.html',
  styleUrls: ['./plot-list.component.css']
})
export class PlotListComponent implements OnInit {

  propList: any;
  search: string;
  col: string;
  sortCol: string;
  searchChk = false;
  payload: any;
  token: any;
  user: string;
  propData: any;
  pid: string;
  pevent: any;

  totalItems: number = 64;
  currentPage: number   = 4;
  smallnumPages: number = 0;

  maxSize: number = 5;
  bigTotalItems: number;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  role: string;

  currentPager: number   = 4;

  // dropdownList = [];
  // selectedItems = [];
  // dropdownSettings = {};
  // municipality:any;
  // prop:any;

  readonly APIURL = environment.apiUrl;
  propertyForm: FormGroup
  constructor(
    private http: HttpClient, 
    private toastr: ToastrService, 
    private router: Router, 
    private formBuilder: FormBuilder,  
    private sharedService: SharedService,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem("token");
    this.pid = localStorage.getItem("pid");
    if(this.token != null && this.token != "" && this.token != "undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.user = this.payload.username;
      this.role = this.payload.role;

      // If page navigation is available on local storage
      if(this.pid != null && this.pid != "" && this.pid != "undefined"){
        this.pevent = {
          page: this.pid,
          itemsPerPage: 10
        }
        this.bigCurrentPage = parseInt(this.pid);
        this.pageChanged(this.pevent);
        
      }else{                        
        this.getProperty();
      }
      
      this.countProperty();
    }
    else{
      this.router.navigateByUrl("/logout");
    }   
  }

  // to get list of all property
  countProperty(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/plot/count").subscribe(
      (res:any) => {
        console.log(res);
        this.bigTotalItems = parseInt(res[1]);
        this.propData = res[0];
        this.SpinnerService.hide();
      },
      err => {       
        this.SpinnerService.hide(); 
        this.sharedService.errorStatus(err.status);
      }
    )
  }

  // to get list of all property
  getProperty(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/plot").subscribe(
      (res:any) => {
        this.propList = res;
        this.SpinnerService.hide();
      },
      err => {
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);
      }
    )
  }

  pageChanged(event: any): void {
    this.SpinnerService.show();
    localStorage.setItem("pid",event.page);
    this.http.get(this.APIURL + "/plot/"+event.page+"/"+event.itemsPerPage).subscribe(
      (res:any) => {
        this.propList = res;
        this.getSortData();
        this.SpinnerService.hide();
      },
      err => {
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);
      }
    )
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
  }

  // To edit existing property
  editProp(plotid){
    this.router.navigateByUrl("editPlot/"+plotid);
  }

  // Delete Property
  deleteProperty(id){    
    var body = {
      plotid : id,
      isDelete : 1,
      actionId : this.user
    }
    this.http.patch(this.APIURL + "/plot/delete/" + id, body).subscribe(
      res => {
        this.getProperty();
        this.toastr.success("Property deleted successfully");        
      },
      err => {        
        console.log(err);
        this.toastr.error("Error! Unable to delete property");
      } 
    )
  }

  house(id){
    this.router.navigateByUrl("houseList/"+id);
  }

  plotView(id){
    this.router.navigateByUrl("plotView/"+id);
  }

  downloadExcel(){ 
    //const Excel = require('exceljs');
    const workbook = new Excel.Workbook();
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var docelement = <HTMLInputElement> document.getElementById("download");
    docelement.innerHTML ="<i class='fa fa-refresh fa-spin'></i> <strong>Downloading...</strong>";
    docelement.disabled = true;
    //create new excel work book
    //let workbook = new Workbook();
    //add name to sheet
    let worksheet = workbook.addWorksheet("Property Data");
    //add column name
    let header=["PropertyId", "Latitude","Longitude", "Property","Street Name","Street No","Suburb","Municipality","Name","Middle Name","Surname","Dob","Gender","Id Number","Email","Telephone","Remark", "ActionId","CreatedAt","Updated At"]
    let headerRow = worksheet.addRow(header);

    for (let x1 of this.propData)
    {
      let x2=Object.keys(x1);
      let temp=[]
      for(let y of x2)
      {
        if(y == "idPhoto" || y == "docPhoto" || y == "isDelete"){
          continue;
        }
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    //set downloadable file name
    let fname="Property Data"

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      this.SpinnerService.show();
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'-'+date+'.xlsx');
      this.SpinnerService.hide();
    });

    docelement.innerHTML ="<i class='fa fa-download'></i> <strong>Download Data</strong>";
    docelement.disabled = false;
  }

  searchData(){
    this.searchChk = true;
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/plot/search/" + this.col +"/"+ this.search).subscribe(
      (res:any) => {
        this.propList = res;
        console.log(res);
        this.bigTotalItems =  Object.keys(this.propList).length;
        this.SpinnerService.hide();
      },
      err => {
        this.SpinnerService.hide();
        console.log(err);
        this.sharedService.errorStatus(err.status);
      }
    );
  }
  
  searchClear(){
    this.searchChk = false;
    this.bigTotalItems = null;
    this.col = "";
    this.search = "";
    this.getProperty();
    this.countProperty();
  }

  getSortData(){
    this.propList.sort(this.dataSort(this.sortCol));
  }

  dataSort(prop){
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }
  }
}
