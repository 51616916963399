import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-houseview',
  templateUrl: './houseview.component.html',
  styles: ['agm-map { height: 300px; /* height is required */ }'
  ]
})
export class HouseviewComponent implements OnInit {

  markers: any;
  latitude: any;
  longitude: any;
  house: any;
  houseid: number;
  token: any;
  payload: any;
  user: string;

  readonly APIURL = environment.apiUrl;

  constructor(
    private SpinnerService: NgxSpinnerService, 
    private sharedService: SharedService, 
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {    
    this.houseid = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.token = localStorage.getItem("token");
    if(this.token != null && this.token != "" && this.token != "Undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.user = this.payload.username;
      this.getHouse();
    }
    else{
      this.router.navigateByUrl("/logout");
    }   
  }

  // to get detail of the property
  getHouse(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/house/"+ this.houseid).subscribe(
      (res:any) => {
          this.latitude = parseFloat(res.latitude);
          this.longitude = parseFloat(res.longitude);
          this.house = res;
          console.log(res);          
          this.SpinnerService.hide();      
      },
      err => {
        console.log(err);
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);
      }
    );
  }

}
