import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit  {

  readonly baseURL = environment.baseUrl;
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router, private formBuilder: FormBuilder) {}
  
  ngOnInit(){  
    localStorage.removeItem("token");
  }
  
  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  login(){
    var payload;
    var role;
    var docelement = <HTMLInputElement> document.getElementById("submit");
    docelement.innerHTML ="<i class='fa fa-refresh fa-spin'></i> Please wait";
    docelement.disabled = true;

    var body = {
      username: this.loginForm.value.username.toUpperCase(),
      password: this.loginForm.value.password
    }

    this.http.post(this.baseURL + "/auth/login",body).subscribe(
      (res:any) => {
        console.log(res);
      payload = JSON.parse(window.atob(res.token.split('.')[1]));      
      role = payload.role;      
      localStorage.setItem('token', res.token);
      this.toastr.success("Login Successful", "Welcome");
      this.router.navigateByUrl('plotList');
      docelement.innerHTML ="<i class='fa fa-sign-in'></i> Login";
      docelement.disabled = false;
    },
    err => {
      if(err.status == 400 || err.status == 401){
        this.toastr.error("Userid or Password is incorrect", "Login Failed");
        docelement.innerHTML ="<i class='fa fa-sign-in'></i> Login";
        docelement.disabled = false;
      }
      if(err.status == 0){
        this.toastr.error("Server is not online", "Login Failed");
        docelement.innerHTML ="<i class='fa fa-sign-in'></i> Login";
        docelement.disabled = false;
      }
      else{
        console.log(err);
        docelement.innerHTML ="<i class='fa fa-sign-in'></i> Login";
        docelement.disabled = false;
      }
    });
  }
 }
