import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: [
  ]
})
export class ProfileComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private toaster: ToastrService) { }
  readonly APIURL = environment.apiUrl;
  payload: any;
  token: any;
  profilePic = "../../../assets/img/avatars/user.svg";
  profile: any;
  
  ngOnInit(){
    this.token = localStorage.getItem("token");
    if(this.token != null){
      this.payload = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));      
    }
    else{
      this.router.navigateByUrl("/login");
    }
    this.UserProfile();
  }

  UserProfile(){
    this.http.get(this.APIURL + '/user/' + this.payload.userId).subscribe(
      (res:any) => {
        this.profile = res;
        console.log(this.profile);
      },err => {
        if(err.status == 401){
          this.router.navigateByUrl("/login");
          this.toaster.error("Login expired. Please login again!",err.error);
        }
        console.log(err.error);
      });
  }
}
