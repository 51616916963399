import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  log:any;
  payload: any;
  username: string;
  readonly APIURL = environment.apiUrl;
  constructor(private http: HttpClient, private toastr: ToastrService, private router: Router) { 
    this.payload = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    this.username = this.payload.username;
  }

  getDateTime(){
    // Get Date and Time
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date+' '+time;
    return dateTime;
  }

  errorStatus(errorCode){    
    if(errorCode == 0){
      this.toastr.error("Server error, System is not online", "Error");
    }
    else if(errorCode==400 || errorCode==500){
      this.toastr.error("Server error, Please contact admin", "Error");
    }
    else if(errorCode == 401){
      this.router.navigateByUrl("/login");
      this.toastr.error("Session expired. Please login again", "Error");
    }
    else{
      this.toastr.error("Error code: "+errorCode+" Please contact admin", "Error");
    }
  }

  // generate log service class
  genLog(activity, id, act, oldval, newval, ActionId){
    this.log = {
      activity : activity,
      activityid : id,
      action : act,
      oldVal : oldval,
      newVal : newval,
      ActionId : ActionId
    }
    console.log(this.log);
    //post new log
    this.http.post(this.APIURL + '/log', this.log).subscribe(
      response => {
        console.log("Log added for "+ activity + " " + act);
      },
      err => {
        this.errorStatus(err.status);
      }); 
  }
}
