import {Component} from '@angular/core';
import { navItems } from '../../_nav';
import { navAdmin } from '../../nav_admin';
import { navUsers } from '../../nav_user';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navAdmin;
  token: any;
  payload: any;
  userid: string;
  role: string;
  
  constructor() {
    this.token = localStorage.getItem("token");  
    if(this.token != null && this.token != 'undefined' && this.token != ''){
      this.payload = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));      
      this.userid = this.payload.sub;
      this.role = this.payload.role;
    }
    // Display menu
    if(this.role === 'Admin'){
      this.navItems = navAdmin;
    }
    else{
      this.navItems = navUsers;
    }
  }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }


}
