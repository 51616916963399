import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-house-list',
  templateUrl: './house-list.component.html',
  styles: [
  ]
})
export class HouseListComponent implements OnInit {

  plotid: number;
  token: any;
  payload: any;
  user: string;
  houseList: any;
  role: string;

  readonly APIURL = environment.apiUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private http: HttpClient,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.plotid = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.token = localStorage.getItem("token");
    if(this.token != null && this.token != "" && this.token != "Undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.user = this.payload.username;
      this.role = this.payload.role;
      this.getHouseList();
    }
    else{
      this.router.navigateByUrl("/logout");
    }
  }

  // Get the house list
  getHouseList(){  
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/house/list/"+ this.plotid).subscribe(
      (res:any) => {
          this.houseList = res;
          this.SpinnerService.hide();      
      },
      err => {
        console.log(err);
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);
      }
    );
  }

  editHouse(houseid){
    this.router.navigateByUrl("editHouse/"+houseid+"/houseList-"+this.plotid);
  }
  
  houseView(houseid){
    this.router.navigateByUrl("houseView/"+houseid);
  }

  // Delete House
  deleteHouse(id){    
    var body = {
      houseid : id,
      isDelete : 1,
      actionId : this.user
    }
    this.http.patch(this.APIURL + "/house/delete/" + id, body).subscribe(
      res => {
        this.getHouseList();              
        this.toastr.success("House deleted successfully");        
      },
      err => {        
        console.log(err);
        this.toastr.error("Error! Unable to delete House");
      } 
    )
  }

}
