import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';
//import { Workbook } from 'exceljs';
import * as Excel from "exceljs/dist/exceljs.min.js";

import * as fs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-all-houses',
  templateUrl: './all-houses.component.html',
  styles: [
  ]
})
export class AllHousesComponent implements OnInit {

  loading: boolean = false;
  token: any;
  payload: any;
  user: string;
  houseList: any;
  houseData: any;
  hid: string;
  hevent: any;
  role: string;

  totalItems: number = 64;
  currentPage: number   = 4;
  smallnumPages: number = 0;

  maxSize: number = 5;
  bigTotalItems: number;
  bigCurrentPage: number = 1;
  numPages: number = 0;

  currentPager: number   = 4;

  readonly APIURL = environment.apiUrl;
  
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private http: HttpClient,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.token = localStorage.getItem("token");
    this.hid = localStorage.getItem("hid");
    if(this.token != null && this.token != "" && this.token != "Undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.user = this.payload.username;
      this.role = this.payload.role;

      // If page navigation is available on local storage
      if(this.hid != null && this.hid != "" && this.hid != "undefined"){
        this.hevent = {
          page: this.hid,
          itemsPerPage: 10
        }
        this.bigCurrentPage = parseInt(this.hid);
        this.pageChanged(this.hevent);
      }
      else{
        this.getHouseList();
      }      
      this.countHouses();
    }
    else{
      this.router.navigateByUrl("/logout");
    }
  }

  // count all the houses
  countHouses(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/house/count").subscribe(
      (res:any) => {
        this.houseData = res[0];
        this.bigTotalItems = parseInt(res[1]);
        this.SpinnerService.hide();
      },
      err => {        
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);
      }
    )
  }

  // Get the house list
  getHouseList(){  
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/house/top").subscribe(
      (res:any) => {
        this.houseList = res;
        this.SpinnerService.hide();  
        console.log(this.houseList);      
      },
      err => {
        console.log(err);
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);        
      }
    );
  }

  pageChanged(event: any): void {
    this.SpinnerService.show();
    localStorage.setItem("hid",event.page);
    this.http.get(this.APIURL + "/house/"+event.page+"/"+event.itemsPerPage).subscribe(
      (res:any) => {
        this.houseList = res;
        this.SpinnerService.hide();
      },
      err => {
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);
      }
    )
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
  }

  // edit house
  editHouse(houseid){
    this.router.navigateByUrl("editHouse/"+houseid+"/allHouses");
  }

  houseView(houseid){
    this.router.navigateByUrl("houseView/"+houseid);
  }

  // Delete House
  deleteHouse(id){    
    var body = {
      houseid : id,
      isDelete : 1,
      actionId : this.user
    }
    this.http.patch(this.APIURL + "/house/delete/" + id, body).subscribe(
      res => {
        this.getHouseList();
        this.countHouses();        
        this.toastr.success("House deleted successfully");        
      },
      err => {        
        console.log(err);
        this.toastr.error("Error! Unable to delete House");
      } 
    )
  }

  // Download data in excel
  downloadExcel(){
    //const Excel = require('exceljs');
    const workbook = new Excel.Workbook();
    var today = new Date();
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

    var docelement = <HTMLInputElement> document.getElementById("download");
    docelement.innerHTML ="<i class='fa fa-refresh fa-spin'></i> <strong>Downloading...</strong>";
    docelement.disabled = true;
    //create new excel work book
    //let workbook = new Workbook();
    //add name to sheet
    let worksheet = workbook.addWorksheet("Property Data");
    //add column name
    let header=["House Id", "Plot Id", "Latitude","Longitude", "People count","Construction","Bedroom","Electricity","Connection Phase","Connection Type","Consumer Type","Loadlist","Owner","Name","Middle Name","Surname","DOB","Gender", "idNumber", "Email","Telephone", "Remark", "ActionId","Created At","Updated At"]
    let headerRow = worksheet.addRow(header);

    for (let x1 of this.houseData)
    {
      let x2=Object.keys(x1);
      let temp=[]
      for(let y of x2)
      {    
        if(y == "idPhoto" || y == "isDelete"){
          continue;
        }    
        temp.push(x1[y])
      }
      worksheet.addRow(temp)
    }

    //set downloadable file name
    let fname="House Data"

    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fname+'-'+date+'.xlsx');
    });

    docelement.innerHTML ="<i class='fa fa-download'></i> <strong>Download Data</strong>";
    docelement.disabled = false;

  }
}
