import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-editplot',
  templateUrl: './editplot.component.html',
  styles: [
  ]
})
export class EditplotComponent implements OnInit {

  token: any;
  payload: any;
  user: string;
  propertyForm: FormGroup;
  plotid: number;
  readonly APIURL = environment.apiUrl;
  
  constructor(private http: HttpClient, 
    private toastr: ToastrService, 
    private router: Router, 
    private formBuilder: FormBuilder,  
    private sharedService: SharedService,
    private SpinnerService: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.plotid = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    if(this.plotid == null){
      this.router.navigateByUrl("/plotList");
    }
    this.token = localStorage.getItem("token");
    if(this.token != null && this.token != "" && this.token != "Undefined"){
      this.payload = JSON.parse(window.atob(this.token.split('.')[1]));      
      this.user = this.payload.username;
      this.getProperty();
    }
    else{
      this.router.navigateByUrl("/logout");
    }
  }

  getProperty(){
    this.SpinnerService.show();
    this.http.get(this.APIURL + "/plot/" + this.plotid).subscribe(
      (res:any) => {
        this.propertyForm = this.fb.group(
          {
            plotid: [res.plotid, Validators.required],
            latitude: [res.latitude, Validators.required],
            longitude: [res.longitude, Validators.required],
            propertyType: [res.propertyType, Validators.required],
            streetName: [res.streetName, Validators.required],
            streetNo: [res.streetNo, Validators.required],
            suburb: [res.suburb, Validators.required],
            municipality: [res.municipality, Validators.required],
            name: [res.name, Validators.required],
            middleName: [res.middleName],
            surname: [res.surname, Validators.required],
            dob: [res.dob, Validators.required],
            gender: [res.gender, Validators.required],
            idNumber: [res.idNumber, Validators.required],
            telephone: [res.telephone],
            email: [res.email]        
          }
        );
        this.SpinnerService.hide();
      },
      err => {
        this.sharedService.errorStatus(err.status);
        this.SpinnerService.hide();
      }
    )    
  }

  updateProperty(){ 
    //Start loading
    this.SpinnerService.show();    
    this.propertyForm.value.plotid = this.plotid;
    this.propertyForm.value.actionId = this.user;
    this.propertyForm.value.isDelete = 0;
    this.http.patch(this.APIURL + "/plot/" + this.plotid, this.propertyForm.value).subscribe(
      res => {         
        this.SpinnerService.hide();              
        this.toastr.success("Property updated successfully");        
        this.router.navigateByUrl("plotList");   
             
      },
      err => {
        console.log(err);
        this.SpinnerService.hide();
        this.sharedService.errorStatus(err.status);        
      }
    );    
  }

  formReset(){

  }

}
